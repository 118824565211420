import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Wrongful Death Attorney",
    description:
      "At Bartlett & Grippe, our wrongful death law firm is dedicated to securing fair and just compensation for you and your family after the loss of a loved one. Contact us for help.",
    heroH1: "Wrongful Death Attorney",
    heroH2:
      "Wrongful Death Attorneys at Bartlett & Grippe Answer Many Key Questions ",
    heroImage: "fabrice-villard-Du41jIaI5Ww-unsplash.jpg",
    heroImageAlt: "Photo of a lone, barren tree on a snowy landscape",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              Wrongful death law firm Bartlett & Grippe will fight to get you
              and your family fair and just compensation for your loss of a
              loved one.
            </h2>
            <p className="mb-8">
              Below are valuable questions about wrongful death claims that our
              wrongful death lawyer has repeatedly been asked over the years by
              clients.
            </p>
            <h2>1) WHAT IS A WRONGFUL DEATH LAWSUIT?</h2>
            <p className="mb-8">What is wrongful death?</p>
            <p className="mb-8">
              A wrongful death is a death due to the negligent, willful, or
              wrongful act, omission or fault of another. Wrongful death or
              accidental death lawsuits are meant to compensate those family
              members left behind after a fatal accident. You also are hurt if
              you relied on the decedent for financial and emotional support. A
              wrongful death may be the result of a negligent or careless act,
              murder, reckless driving, medical malpractice, or many other
              causes listed below.
            </p>
          </div>
          <div className="mb-16">
            <h2>2) DO I HAVE A WRONGFUL DEATH CLAIM?</h2>
            <p className="mb-8">
              This depends on many factors. An experienced wrongful death
              attorney at Bartlett & Grippe can help you determine the answer
              today.Clients often are unsure if they have a viable wrongful
              death case. You have nothing to lose by scheduling a free
              consultation. It is critically important to not delay contacting
              an experienced attorney.
            </p>
          </div>
          <div className="mb-16">
            <h2>
              3) CAN YOU HELP ME IF I LIVE IN A STATE OTHER THAN CONNECTICUT?
            </h2>
            <p className="mb-8">
              <strong>Yes, we help families nationwide.</strong> Over the years
              we have built an extensive network of top-rated wrongful death
              attorneys and experts across the country that we work with
              regularly.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="500"
                src="../images/helping-families-across-the-U.S.-with-wrongful-death-cases.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Wrongful death attorney network across the United States"
              />
            </div>
            <p className="mb-8">
              Over 5000 people a year from across the country reach out to
              Bartlett & Grippe for critical answers to questions about Wrongful
              Death. Four out of five live in a state other than Connecticut.
              Because of the Covid 19 pandemic we have upgraded our technology
              to better serve you and your family. We can now also meet with you
              via phone or video conference. You can securely transfer
              documents, and you can even sign documents electronically from the
              comfort of your home computer or a device such as an iPad or smart
              phone. We are here to help you with the death of a loved one.
            </p>
          </div>
          <div className="mb-16">
            <h2>4) WHO CAN FILE A WRONGFUL DEATH LAWSUIT?</h2>
            <p className="mb-8">
              In Connecticut for example, parents, surviving spouses, and
              dependent children may be entitled to file a wrongful death claim
              depending on the circumstances. Normally the plaintiff is the
              estate of the decedent. Our family law and probate attorneys can
              assist the surviving family members in setting up the necessary
              probate estate that would allow the family to pursue a claim.{" "}
              <Link to="/ct-wrongful-death/wrongful-death-law-information-by-state/">
                For additional information about laws by state click here.
              </Link>
            </p>
          </div>
          <div className="mb-16">
            <h2>5) WHAT DAMAGES ARE RECOVERABLE IN A WRONGFUL DEATH SUIT?</h2>
            <p className="mb-8">
              In a wrongful death action, there are two general types of
              damages, economic and non-economic. Economic damages are monies
              awarded as compensation for monetary losses and expenses that have
              been incurred as a result of the defendant’s negligence. They are
              awarded for such things as the cost of reasonable and necessary
              medical care, funeral expenses and lost earnings. Non-economic
              damages are monies awarded as compensation for non-monetary losses
              and injuries that the plaintiff’s decedent has suffered as a
              result of the defendant’s negligence. They are awarded for
              physical pain and suffering and the destruction of the ability to
              enjoy life’s pleasures.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>6) I LOST MY SPOUSE’S INCOME. WHAT WILL I DO?</h2>
            <p className="mb-8">
              <em>LOSS OF EARNING CAPACITY DUE TO A WRONGFUL DEATH.</em>
            </p>
            <p className="mb-8">
              The destruction of earning capacity, that is, the capacity to
              carry on gainful employment or other means of earning compensation
              during the decedent’s probable lifetime, may be compensated. An
              estate must deduct probable income taxes and necessary personal
              living expenses and adjust for inflation. This value is then
              reduced to its present value. Our wrongful death attorney will
              work with an expert in economics to derive the present value of
              the decedent’s potential earning capacity. This will provide a
              fair figure for the loss of the decedent’s earning capacity as a
              result of the wrongful death.
            </p>
          </div>
          <div className="mb-16">
            <h2>
              7) THEY HAD THEIR WHOLE LIFE AHEAD OF THEM. WHAT IS THAT WORTH?
            </h2>
            <p className="mb-8">
              <em>DESTRUCTION OF CAPACITY TO ENJOY LIFE’S ACTIVITIES.</em>
            </p>
            <p className="mb-8">
              Damages are allowed for the destruction of the decedent’s capacity
              to enjoy life’s activities such as family, work, sports,
              recreation and other aspects of life.
            </p>
          </div>
          <div className="mb-16">
            <h2>8) CAN WE BE COMPENSATED FOR THE WRONGFUL DEATH ITSELF?</h2>
            <p className="mb-8">
              The rule is that insofar as money can do it, the plaintiff may be
              awarded fair, just and reasonable compensation for the loss of
              life. As in the other categories of damages, there is no precise
              mathematical formula. Working with our experienced wrongful death
              lawyer will allow the estate to clearly articulate the value of
              the decedent’s life so as to maximize the compensation awarded.
            </p>
          </div>
          <div className="mb-16">
            <h2>9) MY HUSBAND SUFFERED SO MUCH. IS THAT A FACTOR?</h2>
            <p className="mb-8">
              <em>
                ARE PAIN AND SUFFERING RELATING TO A WRONGFUL DEATH COMPENSABLE?
              </em>
            </p>
            <p className="mb-8">
              Pain and suffering prior to the decedent’s death may be claimed if
              the loved one did not die instantaneously.
            </p>
          </div>
          <div className="mb-16">
            <h2>
              10) I LOST MY SPOUSE, MY LOVE, MY PARTNER IN LIFE. CAN I SUE FOR
              MY LOSS?
            </h2>
            <p className="mb-8">
              <em>WHO CAN PURSUE A RELATING LOSS OF CONSORTIUM CLAIM?</em>
            </p>
            <p className="mb-8">
              The surviving spouse, and due to a recent Supreme Court ruling,
              the children of a person who has died as a result of another
              person’s negligent, reckless or intentional act can recover for
              the loss of their ability to enjoy the companionship of their
              spouse throughout their lifetime. Such a claim is derivative of
              the claim for wrongful death but accrues in favor of the surviving
              spouse, rather than of the estate. Compensable damages for loss of
              consortium include loss of the spouse’s affections, companionship,
              and sexual relations. Our lawyer assists loved ones in
              articulating and quantifying the value of a claim for loss of
              consortium.
            </p>
          </div>
          <div className="mb-16">
            <h2>11) CAN A BYSTANDER PURSUE A CLAIM FOR EMOTIONAL DISTRESS?</h2>
            <p className="mb-8">
              Under certain circumstances, family members who witness the death
              of a family member are entitled to pursue a claim. The claim is
              for bystander emotional distress for the shock and emotional and
              psychological trauma they have endured. If a person is a close
              family member (child, parent, spouse or sibling) and they either
              witnessed the death of a loved one, or arrived on the scene of the
              accident and witnessed the deceased person in the same or similar
              condition as when they had passed away, the family member may be
              entitled to compensation.
            </p>
            <p className="mb-8">
              <Link to="/firm-overview/news-archive/">
                Here is a link to one of our similar wrongful death cases in
                which bystanders were able to be compensated.
              </Link>
            </p>
            <h3>Compensable damages for bystander emotional distress.</h3>
            <p className="mb-8">
              These may include the emotional and psychological injuries
              occasioned by the actual event of witnessing the loved one’s
              death, above and beyond the emotional injuries that would be
              expected from the loss of a loved one. As such, the pursuit of a
              claim of bystander emotional distress requires expert testimony
              and a skilled attorney to differentiate these claims. You may also
              be entitled to double or treble damages depending on the
              circumstances. It is very important to seek experienced counsel as
              soon as possible.
            </p>
          </div>
          <div className="mb-16">
            <h2>12) WHAT ARE SOME TYPES OF WRONGFUL DEATH CASES?</h2>
            <p className="mb-8">
              These are the most serious personal injury cases and can arise out
              of many types of accidents. Accordingly, handling this type of
              case requires a high level of knowledge and skill in personal
              injury law.
            </p>
            <p className="mb-8">
              Our firm has successfully handled hundreds of cases involving:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>
                <Link to="/car-accident-lawyer/">Automobile accidents</Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/motorcycle-accidents/">
                  Motorcycle accidents
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/connecticut-medical-malpractice/">
                  Medical malpractice
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/fire-liability-attorneys-ct/">
                  Fire liability
                </Link>
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/premises-liability/">
                  Premises liability
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/nursing-home-error-attorneys-ct/">
                  Nursing home negligence
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/connecticut-drowning-accident/">
                  Drowning
                </Link>
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/animal-bites/">
                  Animal bites
                </Link>
              </li>
            </ul>
          </div>
          <div className="mb-16">
            <h2>13) HOW LONG DO I HAVE TO FILE A WRONGFUL DEATH CLAIM?</h2>
            <p className="mb-8">
              <em>WHAT IS THE WRONGFUL DEATH STATUTE OF LIMITATIONS?</em>
            </p>
            <p className="mb-8">
              It depends on the state. As an example, pursuant to Connecticut
              General Statute § 52-555, a wrongful death claim must be brought
              within two years of the date of death, except that no such action
              may be brought more than five years from the date of the act or
              omission which ultimately resulted in the person’s death. (
              <a
                style={{ wordWrap: "break-word" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.cga.ct.gov/current/pub/chap_925.htm#sec_52-555"
              >
                https://www.cga.ct.gov/current/pub/chap_925.htm#sec_52-555
              </a>
              ). In addition to this general statute of limitations of two
              years, depending on the particular facts and circumstances that
              led to the death, certain parties may need to be put on notice of
              a potential claim much earlier. Accordingly, should you wish to
              pursue a claim stemming from a loved one’s death, it is imperative
              that you seek legal counsel as soon as possible.
            </p>
            <h3>Wrongful Death Statute of Limitations by State</h3>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../images/wrongful_death_statute_of_limitations_bartlett_legal_group_LLC-768x522.png"
                formats={["auto", "webp", "avif"]}
                alt="Infographic of Wrongful Death Statute of Limitations by State"
              />
            </div>
          </div>
          <div className="mb-16">
            <h2>14) HOW DO I FIND A WRONGFUL DEATH ATTORNEY?</h2>
            <ul className="mb-8 list-decimal list-outside ml-6">
              <li>Read attorney reviews left by clients.</li>
              <li>
                Look at ratings from independent rating services and choose a
                firm that focuses on wrongful death cases.
              </li>
              <li>
                Experience in the courtroom is very important. How many cases
                have they brought to trial?
              </li>
              <li>
                Use Google to research who will be the best fit for you and your
                family.
              </li>
              <li>Read attorney biographies.</li>
              <li>
                Talk to the attorney. If you like the attorney so will the jury.
              </li>
            </ul>
            <p className="mb-8">
              Choose us because we care. Bartlett & Grippe, LLC is one of the
              top-ranked wrongful death law firms in the country. Don’t settle
              for less. We will strive to mount the strongest case possible for
              your claim.
            </p>
            <h3 className="text-center">Experience, integrity, and results</h3>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../images/results-700.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Banner of awards for being a top wrongful death attorney"
              />
            </div>
            <ul className="mb-8 list-disc list-outside ml-6">
              <li>
                Your legal team at Bartlett & Grippe will conduct an in-depth
                investigation of your case based on years of wrongful death
                experience.
              </li>
              <li>Your team will gather evidence from many sources.</li>
              <li>
                We have an extensive network of experts and attorneys nationwide
                that we have built over the years that will help you obtain just
                and reasonable compensation for all injuries and losses, past
                and future, that were caused by the defendant’s legally proven
                negligence.
              </li>
              <li>
                One of our goals is to allow you to focus on personal matters
                and help your family get back to a normal life. While money can
                never replace a loved one, it may help mitigate the financial
                hardship such a tragedy has caused.
              </li>
              <li>
                At Bartlett & Grippe we know that no amount of compensation can
                change the outcome of an accident or replace your loss. Our firm
                will provide you with dedicated, compassionate counsel, allowing
                you to focus on other matters during a difficult time. While we
                cannot turn back the clock on an accident, we can help you seek
                justice by obtaining the compensation to which you are entitled.
              </li>
            </ul>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="900"
                src="../images/Top-rated-injury-trial-lawyer.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Wrongful death attorney at trial in front of jurors"
              />
            </div>
            <p className="mb-8">
              Our mission at Bartlett & Grippe is to be the best wrongful death
              law firm and to have the highest level of client satisfaction.
              Choosing the best attorney for you and your case can be difficult.
            </p>
          </div>
          <div className="mb-16">
            <h2>15) What Are Your Wrongful Death Attorney Fees?</h2>
            <p className="mb-8">
              <strong>No fee unless we win.</strong> We work on what’s called a
              contingency fee basis. In other words, we pay for everything
              associated with your case and are only repaid for our expenses and
              time if we win at trial or you agree to settle your case. If we
              don’t win at trial or settle your case you owe nothing. Our fee is
              the typical 1/3 of the award or settlement.
            </p>
            <h3>
              Please consider calling our firm right now. You’re under no
              obligation and the consultation is free. We are here to help you
              and your family take the fear out of the legal process and to
              maximize your rightful compensation.
            </h3>
            <div className="mb-8 text-center font-bold">
              <a href="tel:+12034634939" class="bg-phone-number">Phone: <span class="bg-phone-number">203-463-4939</span></a>
            </div>
            <p className="mb-8">
              We have the critical experience to fight for your rights during
              negotiations and in court. We understand that you have suffered
              the ultimate loss, and offer compassionate counsel and aggressive
              representation with the goal of securing justice.
            </p>
            <p className="mb-8">
              If you have lost a loved one due to a wrongful death, please
              contact our office today to schedule a free consultation.
            </p>
            <p className="mb-8">
              Attorney case referral – Please go to the firm overview page and
              click on the attorney case referral link.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
